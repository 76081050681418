<template>
  <div class="flex flex-col sm:flex-row gap-4 sm:gap-7">
    <div
      class="text-base font-sans font-semibold"
      :class="isMenuActive ? 'text-dark-green' : 'text-white'"
    >
      {{ $t("Социальные сети") }}
    </div>
    <div class="flex flex-col gap-4">
      <a
        href="https://t.me/minpros_ra"
        target="_blank"
        class="flex flex-row gap-4"
      >
        <img :src="TG" width="24" height="24" />
        <span
          class="font-sans text-base"
          :class="
            isMenuActive
              ? 'text-dark-green'
              : 'text-gray-100 hover:text-gray-300'
          "
          >Telegram</span
        >
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100064620314978"
        target="_blank"
        class="flex flex-row gap-4"
      >
        <img :src="FB" width="24" height="24" />
        <span
          class="font-sans text-base"
          :class="
            isMenuActive
              ? 'text-dark-green'
              : 'text-gray-100  hover:text-gray-300'
          "
          >Facebook</span
        >
      </a>
      <a
        href="https://instagram.com/minprosra?igshid=YmMyMTA2M2Y="
        target="_blank"
        class="flex flex-row gap-4"
      >
        <img :src="INST" width="24" height="24" />
        <span
          class="font-sans text-base"
          :class="
            isMenuActive
              ? 'text-dark-green'
              : 'text-gray-100 hover:text-gray-300'
          "
          >Instagram</span
        >
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import Telegram from "~/assets/svgs/tg-icon.svg";
import Facebook from "~/assets/svgs/fb-icon.svg";
import Instagram from "~/assets/svgs/inst-icon.svg";
export default {
  props: {
    isMenuActive: Boolean,
  },
  setup() {
    return {
      TG: Telegram,
      FB: Facebook,
      INST: Instagram,
    };
  },
};
</script>
