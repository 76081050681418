<template>
  <div class="relative">
    <input
      type="search"
      class="w-full inputCss py-2 border-b bg-transparent focus:outline-none rounded-none border-gray-300 text-base text-gray-300 placeholder:text-gray-300 placeholder:font-sans"
      :placeholder="$t('Поиск по сайту')"
    />
    <button class="w-5 h-5 absolute right-1 top-1">
      <img :src="SEARCH" />
    </button>
  </div>
</template>

<style scoped>
.inputCss {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
<script lang="ts">
import Search from "./../assets/svgs/search.svg";
export default {
  setup() {
    return {
      SEARCH: Search,
    };
  },
};
</script>
