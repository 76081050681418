<template>
  <footer>
    <section class="w-full bg-brunswick-green">
      <div class="container pt-15 pb-10 px-4">
        <section class="lg:flex lg:flex-row lg:justify-between">
          <div class="flex flex-col">
            <div
              class="flex flex-row text-white font-sans text-base space-x-14 sm:space-x-40"
            >
              <div class="flex flex-col space-y-3">
                <nuxt-link
                  class="hover:text-gray-300"
                  to="/about/ministry-powers"
                  >О министерстве</nuxt-link
                >
                <nuxt-link class="hover:text-gray-300" to=""
                  >Деятельность</nuxt-link
                >
                <nuxt-link class="hover:text-gray-300" to="/pressa/news"
                  >Пресс-центр</nuxt-link
                >
                <nuxt-link class="hover:text-gray-300" to=""
                  >Обращения граждан</nuxt-link
                >
                <nuxt-link class="hover:text-gray-300" to=""
                  >Документы
                </nuxt-link>
                <nuxt-link to="/contacts" class="hover:text-gray-300">{{
                  $t("Контакты")
                }}</nuxt-link>
              </div>
              <div class="sm:flex sm:flex-col sm:space-y-12">
                <div class="hidden sm:block sm:w-74">
                  <m-search-field />
                </div>
                <m-social-media :isMenuAktive="'false'"/>
              </div>
            </div>
            <div class="w-full sm:hidden pt-7 basis-full">
              <m-search-field />
            </div>
          </div>

          <div class="hidden lg:flex flex-col items-center space-y-7">
            <img :src="LOGO" class="w-35 h-35" />
            <div class="text-white text-lg font-sans text-center">
              {{ $t("Министерство просвещения Республики Абхазии") }} <br />
             
            </div>
          </div>
        </section>

        <section
          class="flex lg:flex-row flex-col space-y-7 lg:space-y-0 justify-between text-gray-300 text-base mt-20 font-serif text-center"
        >
          <div>
            {{
              $t(
                "Министерство просвещения Республики Абхазия"
              )
            }}
            © 2021
          </div>
          <div>{{ $t("Разработка ASAR Studio") }}</div>
        </section>
      </div>
    </section>
  </footer>
</template>
<script lang="ts">
import Logo from "./../assets/svgs/logo.svg";
export default {
  setup() {
    return {
      LOGO: Logo
    };
  },
};
</script>
