<script setup lang="ts">
import { ref } from "vue";
import ARROW from "~/assets/svgs/arrow.svg";
const emit = defineEmits(["clickOnLink"]);
const menuItems = [
  {
    title: "О министерстве",
    list: [
      { item: "Руководство", link: "/about/staff/" },
      { item: "О министерстве", link: "/about/ministry-powers" },
      {
        item: "Подведомственные организации",
        link: "/about/organizations",
      },
      { item: "Реквизиты", link: "/about/requisites" },
      { item: "Коллегия", link: "/about/commission" },
    ],
  },
  {
    title: "Деятельность",
    list: [
      { item: "Конкурсы и олимпиады", link: "" },
      {
        item: "План мероприятий",
        link:
          "https://minpros.storage.yandexcloud.net/plan_meropriyaty_4eaa32e7bb.pdf?updated_at=2022-10-24T08:15:09.413Z",
      },
    ],
  },
  {
    title: "Пресс-центр",
    list: [
      { item: "Аккредитация", link: "/pressa/accreditation" },
      { item: "Медиатека", link: "/pressa/media" },
      { item: "Новости", link: "/pressa/news" },
    ],
  },
  {
    title: "Документы",
    list: [
      {
        item: "Трудовой кодекс",
        link:
          "https://minpros.storage.yandexcloud.net/trudovoi_kodeks_05fc40eb1a.pdf?updated_at=2022-10-24T08:25:41.550Z",
      },
      {
        item: "Закон о гражданстве РА",
        link:
          "https://minpros.storage.yandexcloud.net/zacon_ra_cdad22c484.pdf?updated_at=2022-10-24T08:15:09.396Z",
      },
      {
        item: "Положение",
        link:
          "https://minpros.storage.yandexcloud.net/postanovlenie_compressed_1f41362d40.pdf?updated_at=2022-10-24T08:26:28.976Z",
      },
    ],
  },
  {
    title: "Электронные книги",
    list: [{ item: "", link: "/books/all" }],
  },
  {
    title: "Обращения граждан",
    list: [
      { item: "Заявления", link: "/appeals/claim" },
      { item: "Контакты", link: "/contacts" },
    ],
  },
];

function onClick(item) {
  if (item) {
    emit("clickOnLink");
  }
}
</script>
<template>
  <section class="p-4 !h-full !w-full">
    <div class="container mx-auto flex flex-col justify-between">
      <div class="lg:hidden h-full overflow-hidden flex-col space-y-9 flex">
        <div v-for="item in menuItems" :key="item">
          <m-dropdown :menu="item" @handle-click="onClick" />
        </div>
      </div>

      <section class="lg:flex flex-col space-x-8 hidden lg:flex-row lg:space-x-20">
        <div>
          <div class="text-dark-green text-base font-serif lg:mb-5 lg:mt-24">
            {{ $t("О министерстве") }}
          </div>
          <div class="flex flex-col space-y-2">
            <ul
              class="text-gray-300 text-base font-serif"
              v-for="list in menuItems[0].list"
              :key="list"
            >
              <li>
                <nuxt-link :to="list.link" @click="onClick(list.link)">{{
                  list.item
                }}</nuxt-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="flex flex-col">
          <div>
            <div class="text-dark-green text-base font-serif lg:mb-5 lg:mt-24">
              {{ $t("Деятельность") }}
            </div>
            <div class="flex flex-col space-y-2">
              <ul
                class="text-gray-300 text-base font-serif flex flex-col space-y-2"
                v-for="list in menuItems[1].list"
                :key="list"
              >
                <li>
                  <a :href="list.link" target="_blank" @click="onClick(list.link)">{{
                    list.item
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div class="text-dark-green text-base font-serif lg:mb-5 lg:mt-8">
              {{ $t("Пресс-центр") }}
            </div>
            <div class="flex flex-col space-y-2">
              <ul
                class="text-gray-300 text-base font-serif flex flex-col space-y-2"
                v-for="list in menuItems[2].list"
                :key="list"
              >
                <li>
                  <nuxt-link :to="list.link" @click="onClick(list.link)">{{
                    list.item
                  }}</nuxt-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div>
            <div class="text-dark-green text-base font-serif lg:mb-5 lg:mt-24">
              {{ $t("Документы") }}
            </div>
            <div class="flex flex-col space-y-2">
              <ul
                class="text-gray-300 text-base font-serif flex flex-col space-y-2"
                v-for="list in menuItems[3].list"
                :key="list"
              >
                <li>
                  <a :href="list.link" target="_blank" @click="onClick(list.link)">{{
                    list.item
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div class="text-dark-green text-base font-serif lg:mt-8">
              <nuxt-link to="/books/all" @click="onClick('/books/all')">
                Электронные книги
              </nuxt-link>
            </div>
            <div class="flex flex-col space-y-2">
              <ul
                class="text-gray-300 text-base font-serif flex flex-col space-y-2"
                v-for="list in menuItems[4].list"
                :key="list"
              >
                <li>
                  <nuxt-link :to="list.link" @click="onClick(list.link)">{{
                    list.item
                  }}</nuxt-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div class="text-dark-green text-base font-serif lg:mb-5 lg:mt-24">
            {{ $t("Обращения граждан") }}
          </div>
          <div class="flex flex-col space-y-2">
            <ul
              class="text-gray-300 text-base font-serif flex flex-col space-y-2"
              v-for="list in menuItems[5].list"
              :key="list"
            >
              <li>
                <nuxt-link :to="list.link" @click="onClick(list.link)">{{
                  list.item
                }}</nuxt-link>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <div class="flex flex-row justify-between mt-28">
        <m-social-media :isMenuActive="'true'" />
        <div class="flex flex-row space-x-8 text-base font-serif text-gray-300">
          <m-lang-selector />
        </div>
      </div>
    </div>
  </section>
</template>
