<template>
  <div class="h-full border-none" @click="isOpen = !isOpen">
    <div class="flex flex-row justify-between items-center">
      <div
        class="font-serif text-base font-semibold cursor-pointer"
        :class="!isOpen ? 'text-dark-green' : ''"
      >
        <div v-if="!isSingle">
          {{ menu.title }}
        </div>
        <div v-for="item in menu.list" :key="item" v-else>
          <nuxt-link :to="item.link" @click="$emit('handleClick', item.link)">
            {{ menu.title }}
          </nuxt-link>
        </div>
      </div>
      <div>
        <img
          :src="ARROW"
          :class="[!isOpen ? 'rotate-0' : 'rotate-180']"
          class="duration-300"
          v-if="!isSingle"
        />
      </div>
    </div>
    <div class="duration-500 h-0 overflow-hidden" v-if="!isSingle" ref="itemsRef">
      <div
        v-for="item in menu.list"
        :key="item"
        class="text-lg text-gray-300 font-serif mt-4 cursor-pointer"
      >
        <a
          v-if="item.link.includes('http')"
          :href="item.link"
          @click="$emit('handleClick', item.link)"
          >{{ item.item }}</a
        >
        <nuxt-link v-else :to="item.link" @click="$emit('handleClick', item.link)">{{
          item.item
        }}</nuxt-link>
      </div>
    </div>
  </div>
</template>
<script>
import Arrow from "./../assets/svgs/arrow.svg";
import { ref, toRefs } from "vue";
export default {
  props: {
    menu: Object,
  },
  setup(props) {
    const isOpen = ref(false);
    const itemsRef = ref(null);
    const { menu } = toRefs(props);
    const isSingle = computed(() => {
      if (menu.value.list.length === 1) {
        return true;
      } else {
        return false;
      }
    });

    watch(isOpen, () => {
      if (!isSingle.value) {
        if (isOpen.value) {
          itemsRef.value.style.height = `${itemsRef.value.scrollHeight}px`;
          itemsRef.className = "duration-500 overflow-hidden";
        } else {
          itemsRef.className = "duration-500 h-0 overflow-hidden";
          itemsRef.value.style.height = "0px";
        }
      }
    });

    return {
      ARROW: Arrow,
      isOpen,
      itemsRef,
      isSingle,
    };
  },
};
</script>
