<script setup lang="ts">
import { watch } from "vue";
import { useCookie } from "#app";
import { useI18n } from "vue-i18n";
import { COOKIE_KEYS } from "./../utils/constants";

const savedLocale = useCookie(COOKIE_KEYS.i18n);

const { locale, availableLocales } = useI18n({
  useScope: "global",
  messages: {
    ru: {
      ru: "Рус",
      abh: "Аԥс",
    },
  },
});

watch(locale, () => {
  savedLocale.value = locale.value;
  location.reload();
});
</script>

<template>
  <div class="flex flex-row space-x-7">
    <div v-for="_locale in availableLocales" :key="_locale" :value="_locale">
      <input
        type="radio"
        :id="_locale"
        :value="_locale"
        v-model="locale"
        class="hidden"
      />
      <label :for="_locale">{{ $t(_locale) }}</label>
    </div>
  </div>
</template>

<style scoped>
input[type="radio"]:checked + label {
  border-bottom: 1px solid #7c8785;
}
</style>
